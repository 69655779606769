import React, { FC } from 'react';
import { Path, Svg } from 'react-native-svg';
import { IconProps } from './Icon.props';

export const ShareIcon: FC<IconProps> = ({ fill, ...props }) => (
  <Svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <Path
      d="M21.1667 20.4413C20.4493 20.4413 19.8021 20.7229 19.3114 21.1762L12.5817 17.212C12.6338 16.9968 12.6667 16.7724 12.6667 16.5435C12.6667 16.3146 12.6338 16.0913 12.5817 15.8761L19.24 11.9509C19.7613 12.447 20.4504 12.7234 21.1667 12.7236C22.7295 12.7236 24 11.4404 24 9.86179C24 8.28323 22.7295 7 21.1667 7C19.6038 7 18.3333 8.28323 18.3333 9.86179C18.3333 10.0907 18.3662 10.3151 18.4183 10.5292L11.76 14.4544C11.2384 13.9588 10.5495 13.6825 9.83333 13.6817C8.27047 13.6817 7 14.9649 7 16.5435C7 18.1221 8.27047 19.4053 9.83333 19.4053C10.5496 19.4051 11.2387 19.1287 11.76 18.6326L18.4897 22.5968C18.4402 22.8014 18.4147 23.0111 18.4138 23.2218C18.414 23.7714 18.5756 24.3086 18.878 24.7655C19.1805 25.2224 19.6102 25.5784 20.113 25.7887C20.6158 25.9989 21.169 26.0538 21.7026 25.9465C22.2363 25.8393 22.7265 25.5746 23.1113 25.1859C23.4961 24.7973 23.7581 24.3022 23.8643 23.7631C23.9705 23.2241 23.9162 22.6654 23.708 22.1575C23.4999 21.6497 23.1474 21.2156 22.695 20.9101C22.2427 20.6047 21.7108 20.4415 21.1667 20.4413Z"
      fill={fill}
    />
  </Svg>
);
