import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InteractionState,
  Pressable,
  TextInput,
  TextInputProps,
} from 'react-native';
import { useTheme } from '@emotion/react';
import { css } from '@emotion/native';

import { SearchIcon } from './icons';

export const SearchField: FC<TextInputProps> = (props) => {
  const { t } = useTranslation('translation');
  const theme = useTheme();

  return (
    <Pressable
      style={css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 8,
        paddingHorizontal: 16,
        borderWidth: 2,
        borderColor: 'transparent',
        backgroundColor: theme.colors.main10,
      })}
    >
      {({ pressed }: InteractionState) => (
        <>
          <SearchIcon fill={theme.colors.main50} width={24} height={24} />
          <TextInput
            placeholder={t('Search')}
            style={css({
              width: '100%',
              padding: 0,
              borderWidth: 0,
              color: pressed ? theme.colors.main80 : theme.colors.main50,
              marginLeft: 16,
              fontSize: 18,
              fontFamily: theme.fontFamilyMedium,
            })}
            {...props}
          />
        </>
      )}
    </Pressable>
  );
};
