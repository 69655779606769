import React, { FC } from 'react';
import { Circle, Path, Rect, Svg } from 'react-native-svg';
import { IconProps } from './Icon.props';
import { useTheme } from '@emotion/react';

export const NoPlaylists: FC<IconProps> = (props) => {
  const theme = useTheme();

  return (
    <Svg
      width="192"
      height="192"
      viewBox="0 0 192 192"
      opacity={0.5}
      {...props}
    >
      <Rect
        x="55.5771"
        y="58.6265"
        width="69.9032"
        height="69.9032"
        rx="3.5"
        fill={theme.colors.bg}
        stroke={theme.colors.main}
      />
      <Circle cx="90.5289" cy="93.5778" r="26.234" stroke={theme.colors.main} />
      <Circle cx="90.529" cy="93.5783" r="7.34585" stroke={theme.colors.main} />
      <Rect
        x="50.123"
        y="67.9766"
        width="80.8114"
        height="80.8114"
        rx="3.5"
        fill={theme.colors.bg}
        stroke={theme.colors.main}
      />
      <Circle
        cx="90.5286"
        cy="108.382"
        r="30.3469"
        stroke={theme.colors.main}
      />
      <Circle cx="90.529" cy="108.382" r="8.5529" stroke={theme.colors.main} />
      <Rect
        x="107.618"
        y="18.6707"
        width="61.1653"
        height="61.1653"
        rx="3.5"
        transform="rotate(26.5367 107.618 18.6707)"
        fill={theme.colors.bg}
        stroke={theme.colors.main}
      />
      <Path
        d="M117.375 65.9756C117.165 65.8704 117.078 65.6139 117.192 65.4076C117.696 64.491 118.284 63.7523 118.956 63.1916C119.731 62.5303 120.513 62.047 121.3 61.7416C122.101 61.4107 123.105 61.0861 124.312 60.7678C125.481 60.4622 126.421 60.1694 127.133 59.8895C127.883 59.5968 128.594 59.158 129.267 58.573C129.966 58.0008 130.557 57.2316 131.039 56.2655C131.89 54.562 131.927 52.8653 131.151 51.1754C130.375 49.4855 128.805 48.0501 126.44 46.8694C124.61 45.9552 122.887 45.4286 121.273 45.2896C119.807 45.1634 118.383 45.3464 117.001 45.8388C116.773 45.92 116.519 45.8035 116.443 45.5742C116.372 45.3626 116.479 45.1319 116.69 45.0576C119.79 43.9638 123.16 44.3262 126.802 46.1448C128.455 46.97 129.758 47.9543 130.712 49.0977C131.691 50.2537 132.27 51.4799 132.448 52.7761C132.652 54.0851 132.443 55.3624 131.821 56.6082C131.288 57.6761 130.64 58.5279 129.878 59.1637C129.141 59.8122 128.372 60.3019 127.572 60.6328C126.784 60.9382 125.786 61.25 124.579 61.5684C123.397 61.8994 122.432 62.2112 121.682 62.5039C120.97 62.7839 120.259 63.2227 119.548 63.8204C118.94 64.318 118.409 64.9742 117.956 65.789C117.841 65.9947 117.586 66.0808 117.375 65.9756ZM113.738 73.2597C113.484 73.1328 113.306 72.9168 113.204 72.6118C113.102 72.3069 113.114 72.0273 113.241 71.773C113.381 71.4934 113.597 71.3153 113.889 71.2389C114.194 71.1371 114.474 71.1497 114.728 71.2766C115.008 71.4163 115.186 71.6323 115.262 71.9245C115.377 72.2041 115.364 72.4837 115.225 72.7634C115.098 73.0176 114.882 73.1956 114.577 73.2975C114.297 73.412 114.017 73.3994 113.738 73.2597Z"
        fill={theme.colors.main}
      />
      <Rect
        x="43.5"
        y="80.4429"
        width="94.0571"
        height="94.0571"
        rx="3.5"
        fill={theme.colors.bg}
        stroke={theme.colors.main}
      />
      <Circle
        cx="90.5287"
        cy="127.472"
        r="35.3412"
        fill={theme.colors.bg}
        stroke={theme.colors.main}
      />
      <Circle
        cx="90.5284"
        cy="127.472"
        r="10.0186"
        fill={theme.colors.bg}
        stroke={theme.colors.main}
      />
    </Svg>
  );
};
