import { ITheme } from './theme.interface';

export const theme: ITheme = {
  fontFamily: 'Montserrat',
  fontFamilyMedium: 'Montserrat-Medium',
  fontFamilySemiBold: 'Montserrat-SemiBold',
  fontFamilyBold: 'Montserrat-Bold',
  fontFamilyExtraBoldItalic: 'Montserrat-ExtraBoldItalic',

  colors: {
    accent: '#8dce24',
    accent50: 'rgba(141, 206, 36, .5)',
    bg: '#151518',
    bg50: 'rgba(21, 21, 24, .5)',
    bg75: 'rgba(21, 21, 24, .75)',
    main: '#fafafa',
    main5: 'rgba(250, 250, 250, .05)',
    main10: 'rgba(250, 250, 250, .1)',
    main20: 'rgba(250, 250, 250, .2)',
    main50: 'rgba(250, 250, 250, .5)',
    main80: 'rgba(250, 250, 250, .8)',
    main5onBg: '#202023',
    main10onBg: '#2c2c2f',
    main20onBg: '#434345',
    main50onBg: '#888889',
    main80onBg: '#cccccd',
    danger: '#e94d43',
  },
};
