export const ROUTES = {
  Start: '/',
  Playlists: '/playlists',
  Playlist: '/playlist',
  Artist: '/artist',
  Search: '/search',
  Game: '/game',
  Results: '/result',
  Statistics: '/statistics',
};
